import React from "react"
import Seo from "../components/global/Seo"
import Header from "../components/global/header"
import Footer from "../components/global/footer"

export default function PolicyPage() {
  return (
    <>
      <Seo
        title={`Політика конфіденційності`}
        description={``}
        image={``}
        url={``}
        noindex="noindex"
      />
      <Header />

      <div className="container-my">
        <h1>Політика конфіденційності сайту</h1>
        <p>EMAIL</p>
        <p>
          Ця політика конфіденційності діє для всіх продуктів (програм для ЕОМ -
          далі "Додатки"), і веб-сайтів, що відносяться до компанії BN Consult
          (надалі - “Компанія” або “Ми”). У деяких випадках Компанія залишає за
          собою право публікувати окремі примітки про конфіденційність для
          певних продуктів, а також у будь-який час змінювати політику
          конфіденційності на свій розсуд.
        </p>
        <p>
          Для вашої зручності, сервіси та сайти Компанії можуть містити
          посилання на інші веб-сайти. Ми не несемо відповідальності за політику
          конфіденційності або зміст цих сайтів. Якщо у вас є питання або
          сумніви з приводу цієї політики, ви повинні зв'язатися з нами за
          адресою електронної пошти{" "}
          <a href="mailto:info@bnconsult.com.ua">info@bnconsult.com.ua</a>. Ця
          політика поширюється тільки на інформацію, зібрану на сайтах Компанії
          і веб-додатки, що працюють у рамках сайту.
        </p>
        <h2>Контент від читачів (у тому числі коментарі)</h2>
        <p>
          Ми надаємо вам можливість брати участь у дискусіях на нашому сайті,
          включаючи коментарі, рекомендації, точки зору читачів, огляди,
          рейтинги та ін. Будь-яка інформація, яку ви надаєте, вказавши ваше
          вигадане або справжнє ім’я, ID у будь-якій соцмережі, або будь-яке
          зображення або фотографію, стає надбанням громадськості і може бути
          використаним нами у рекламних або комерційних цілях в будь-яких
          засобах масової інформації.
        </p>
        <p>
          Якщо Ви вирішили брати участь у громадській дискусії, ви повинні
          знати, що будь-яка особиста інформація, надана вами, може бути
          прочитана, зібрана чи використана іншими користувачами цих послуг, і
          може бути використана для розсилки небажаних повідомлень. Ми не несемо
          відповідальність за особисту інформацію, яку ви надаєте у коментарях і
          не зобов’язані публікувати, видаляти або редагувати будь-який з ваших
          коментарів.
        </p>
        <p>
          Якщо ви вирішили рекомендувати будь-яку статтю з нашого сайту у
          Facebook, ці дії регулюються Політикою конфіденційності Facebook. Ми
          не відстежуємо і не зберігаємо записи цих дій. Теж саме стосується
          політики Twitter, Vkontakte та інших соціальних мереж.
        </p>
        <p>
          Якщо ви зареєстровані на нашому сайті, ми можемо відстежувати і
          аналізувати ваші дії на ньому. Беручи участь у громадській дискусії,
          ви погоджуєтеся на те, що Ваші дії будуть публічними. Крім того, вони
          можуть бути включені у RSS-канали, API, і стати доступними для інших
          веб-сайтів за допомогою інших форматів. В результаті, надана вами
          інформація може з’явитись на інших веб-сайтах, блогах, та ін. Майте на
          увазі, що ми не несемо відповідальності за будь-яку особисту
          інформацію, яку Ви вирішили оприлюднити через цей функціонал, і ви
          погоджуєтесь, що ця інформація буде вважатися такою, що була надана
          вами, а не Компанією.
        </p>
        <h2>
          Обмеження відповідальності за достовірність отриманої інформації.
        </h2>
        <p>
          Ми не перевіряємо достовірність отриманої про користувачів інформації
          і не контролюємо правосуб’єктність користувачів.
        </p>
        <p>
          Інформація, яка може бути оброблена в процесі взаємодії з користувачем
          (далі - "Користувач") сайтів Компанії:
        </p>
        <ul>
          <li>
            Інформація, що надається Користувачами соціальних мереж - під час
            використання Програми може використовуватися надана Користувачем
            особиста інформація. Інформація про відвідування - при доступі до
            служб Компанії через браузер, програму або інший клієнт сервери
            Компанії автоматично записують певну інформацію. Ці журнали сервера
            можуть містити інформацію про веб-запити, IP-адресу, типи і мову
            браузера, дату і час запиту, а також містити один або декілька
            файлів cookie, за якими можна визначити браузер або аккаунт
            Користувача.
          </li>
          <li>
            Користувацькі повідомлення - коли Користувач в процесі взаємодії при
            використанні Програми відправляє повідомлення, у тому числі в служби
            підтримки, можливе збереження і обробка цих повідомлень для
            відповідей на запитання і подальшого вдосконалення функціонування
            Програми та сервісів Компанії. Для зв'язку з Користувачем з питань
            роботи Програми може використовуватися адреса електронної пошти
            надана Користувачем.
          </li>
          <li>
            Треті особи - дана політика конфіденційності відноситься виключно до
            Додатків та інших служб Компанії.
          </li>
        </ul>
        <p>
          Компанія не контролює сайти, які використовують програми інші продукти
          та служби Компанії. Ці сайти можуть розміщувати на Ваш комп'ютер
          власні файли cookie, збирати дані або запитувати у користувачів
          соціальних мереж особисту інформацію надану останніми на свій страх і
          ризик.{" "}
        </p>
        <p>
          Крім цього, інформація, що надається Користувачами соціальних мереж
          може бути використана в наступних цілях:
        </p>
        <ul>
          <li>підтримка, захист і удосконалення Додатків і служб Компанії;</li>
          <li>захист прав та власності Компанії та Користувачів.</li>
        </ul>
        <p>
          Цим підтверджується, що використання інформації, зазначеної
          Користувачем, дозволено для цілей передбачених політикою
          конфіденційності Компанії.
        </p>
        <p>
          Більшість браузерів спочатку налаштовані на отримання файлів cookie,
          однак ви можете скинути ці налаштування і вказати, щоб браузер
          блокував всі файли cookie або оповіщав про відправку цих файлів.
          Просимо врахувати, що деякі функції та служби Компанії не зможуть
          працювати належним чином, якщо відключити файли cookie.
        </p>
        <h2>Надання доступу до інформації</h2>
        <p>
          Компанія надає доступ до особистої інформації іншим компаніям і
          приватним особам, які не мають відношення до Компанії, лише за таких
          обмежених обставин:
        </p>
        <ul>
          <li>
            Має місце наявність дозволу Користувача. Для передачі будь-якої
            конфіденційної інформації Компанії потрібна явна згода Користувача.
          </li>
          <li>
            Компанія надає такого роду інформацію дочірнім і афільованим
            компаніям, а також іншим довіреним організаціям та особам тільки з
            метою обробки інформації від нашого імені.
          </li>
          <li>
            У Компанії є підстави вважати, що доступ, використання, збереження
            або розкриття такої інформації необхідне для:
            <ul>
              <li>
                Дотримання будь-яких чинних законів, постанов, або дійсного
                запиту з державних органів;
              </li>
              <li>Розслідування потенційних порушень;</li>
              <li>
                При виявленні і запобіганні шахрайським діям, а також вирішенні
                проблем безпеки та усунення технічних неполадок.
              </li>
            </ul>
          </li>
        </ul>
        <h2>Захист інформації</h2>
        <p>
          Компанія вживає всіх необхідних заходів для захисту даних від
          несанкціонованого доступу, зміни, розкриття чи знищення. До цих
          заходів належать, зокрема, внутрішня перевірка процесів збору,
          зберігання та обробки даних і заходів безпеки, включаючи відповідне
          шифрування і заходи щодо забезпечення фізичної безпеки даних для
          запобігання неавторизованого доступу до систем, в яких ми зберігаємо
          особисті дані.{" "}
        </p>
        <p>
          Компанія надає доступ до інформації користувачів тільки тим
          співробітникам, підрядникам та агентам Компанії, яким необхідно мати
          цю інформацію для здійснення операцій, які виконуються від нашого
          імені.
        </p>
        <h2>Зміна даної політики конфіденційності </h2>
        <p>
          Користувач, використовуючи Програми, прийняв умови цієї політики
          конфіденційності Компанії і враховує, що дана політика
          конфіденційності може час від часу змінюватися. Зміни, що вносяться до
          політики конфіденційності, публікуються Компанією на цій сторінці.{" "}
        </p>
        <p>
          Для питань щодо політики конфіденційності, Користувач може зв'язатися
          з Компанією через веб-сайт або використовуючи email:{" "}
          <a href="mailto:info@bnconsult.com.ua">info@bnconsult.com.ua</a>.
        </p>
      </div>
      <Footer />
    </>
  )
}
